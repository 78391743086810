<template>
	<div>
        <v-container fluid :class="responsive == 'PC' ? 'grid-list-xl pb-0 mt-12' : 'flex-b grid-list-xl mt-12 pb-0 px-0 pt-0' ">
            <signal-mobile-change v-if="responsive != 'PC'"></signal-mobile-change>
            <!-- Trading Signal Results -->
            <v-row class="mx-2">
                <v-col cols="12" sm="12" md="12" class="lightred--text pl-1 text-center mb-5 mt-3">
                    <span class="fw-bold font-2x">{{$t("home.tradingSignalResults")}} ({{$t("home.version")}})</span>
                </v-col>
                <v-col cols="12" md="4" xs="4" class="pa-0" v-for="(symbol, index) in existSignalSymbols" :key="index">
                    <!-- id加上index是为了不和图表的id重复 -->
                    <!-- border-shortdash -->
                    <div class="" :id="index + '-' + symbol" >
                        <signal-portfolio-chart class="my-4" :symbol="symbol"></signal-portfolio-chart>
                    </div>
                </v-col>
            </v-row>
        </v-container>
        <app-footer></app-footer>
        <!-- <mobile-btn></mobile-btn> -->
	</div>
</template>
<style>

</style>
<script>
    import Vue from 'vue'
    import config from '@/config.js';
    import { mapGetters } from "vuex";
    import SignalPortfolioChart from "@/highcharts/SignalPortfolioChart";
    export default {
        data(){
            return {
                
            }
        },
        components: {
            SignalPortfolioChart
        },
        created(){
            this.$store.dispatch("signalPageHandler", this.$t("header.tradingSignalResults"));
        },
        mounted(){

        },
        computed: {
            ...mapGetters(["responsive",'darkMode','signalSymbols']),
            // 存在信号的交易对
            existSignalSymbols(){
                let symbols = JSON.parse(JSON.stringify(this.signalSymbols));
                // 删除这几个没有新的的交易对
                symbols.remove('ONTUSD');
                symbols.remove('XLMUSD');
                symbols.remove('IOSTUSD');
                symbols.remove('ALGOUSD');
                return symbols;
            }
        },
        watch:{

        },
        methods: {

        },
    }
</script>